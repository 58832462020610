// @ts-nocheck
import * as Actions from './ActionTypes';
import { Dispatch } from 'redux';
import { fbauth, googleAuthProvider, Firestore } from '../../Firebase/firebase';
import { indexFunc } from '../Utils';

const userExistsInFirestore = async (user: any) => {
  const userDoc = await Firestore.collection('users').doc(user.uid).get();
  return userDoc.exists;
};

const createUserInFirestore = async (user: any) => {
  const userExists = await userExistsInFirestore(user);
  if (userExists) return;

  const userData: any = {
    email: user.email,
    index: indexFunc(user.email),
  };

  await Firestore.collection('users').doc(user.uid).set(userData);
};

export const authSignUp = (userData) => async (dispatch) => {
  try {
    dispatch({ type: Actions.AUTH_START });

    // Create firebase user profile/doc with email & password.
    const res = await fbauth.createUserWithEmailAndPassword(userData.email, userData.password);

    // Create entry in Firestore for indexing.
    await createUserInFirestore(res.user);

    dispatch({ type: Actions.AUTH_LOGIN });
  } catch (error) {
    console.error(error);
    dispatch({ type: Actions.AUTH_FAIL, payload: error.message });
  }
};

export const authGoogleLogin = () => async (dispatch: Dispatch) => {
  try {
    const authResult = await fbauth.signInWithPopup(googleAuthProvider);
    await createUserInFirestore(authResult.user);
  } catch (error) {
    const ignoreCodes = ['auth/popup-closed-by-user', 'auth/cancelled-popup-request'];
    if (ignoreCodes.includes(error.code)) return;
    console.error(error);
    dispatch({ type: Actions.AUTH_FAIL, payload: error.message });
  }
};

export const authLogin = (userData) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: Actions.AUTH_START });

    await fbauth.signInWithEmailAndPassword(userData.email, userData.password);

    dispatch({ type: Actions.AUTH_LOGIN });
  } catch (error) {
    console.error(error);
    dispatch({ type: Actions.AUTH_FAIL, payload: error.message });
  }
};

export const authLogout = () => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: Actions.AUTH_START });

    await fbauth.signOut();

    dispatch({ type: Actions.AUTH_LOGOUT });
  } catch (error) {
    console.error(error);
    dispatch({ type: Actions.AUTH_FAIL, payload: error.message });
  }
};

// Reset: reset authfail message and loading status.
export const authReset = () => {
  return {
    type: Actions.AUTH_RESET,
  };
};
