import React, { createRef } from 'react';
import { connect } from 'react-redux';
import { ReduxState } from '../../Store';
import { authReset, authSignUp } from '../../Store/Actions/AuthActions';
import { ThunkDispatch } from 'redux-thunk';
import { validEmail } from '../Utils/Utils';

interface SignUpFormProps {
  isLogin: boolean;
  setContainerHeight: (height?: string) => {};
}

type Props = SignUpFormProps & LinkStateProps & LinkDispatchProps;

interface SignUpFormState {
  email: string;
  password: string;
  password2: string;
  pwSameness: boolean;
  checkPwMsg: boolean;
}

class SignUpForm extends React.Component<Props, SignUpFormState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      password2: '',
      pwSameness: false,
      checkPwMsg: false,
    };
  }

  // private errorMsgRef = createRef<HTMLSpanElement>();

  handleSignUp = () => {
    const isEmailValid = validEmail(this.state.email);
    isEmailValid && this.props.signupThunk(this.state);
  };

  handleChange = async (e: React.SyntheticEvent<HTMLInputElement>) => {
    if (this.props.authFail) {
      this.props.authReset();
      this.props.setContainerHeight();
    }

    const target = e.target as HTMLInputElement;
    const isPw = target.name.includes('password');
    const pwSameness = isPw ? this.passwordSame(e) : this.state.pwSameness;

    await this.setState({
      [target.name as keyof SignUpFormState]: target.value as string,
      checkPwMsg: false,
      pwSameness,
    } as Pick<SignUpFormState, keyof SignUpFormState>);
  };

  handlePwBlur = () => {
    // If passwords have input and are not the same, show error msg.
    const pw1 = this.state.password;
    const pw2 = this.state.password2;
    if (pw1 && pw2 && pw1 !== pw2) {
      this.setState({ checkPwMsg: true });
    }
  };

  passwordSame = (e: React.SyntheticEvent<HTMLInputElement>) => {
    // Check inputted password is same as other pw field.
    const target = e.target as HTMLInputElement;
    const pw1 = target.value;
    const pw2 = target.name === 'password' ? this.state.password2 : this.state.password;
    return pw1 === pw2;
  };

  componentDidUpdate = (prevProps: Props) => {
    if (prevProps.authFail !== this.props.authFail) {
      this.props.setContainerHeight('signup');
    }
  };

  render() {
    const { email, password, password2, pwSameness, checkPwMsg } = this.state;
    const { authFail, isLogin } = this.props;

    return (
      <form className={`signup-form${isLogin ? ' hidden' : ''}`}>
        <label htmlFor="email">Email</label>
        <input
          name="email"
          type="text"
          value={email}
          placeholder={'Email or Username'}
          onChange={this.handleChange}
        />

        <label htmlFor="password">Password</label>
        <input
          name="password"
          type="password"
          value={password}
          placeholder={'Password (at least 6 characters)'}
          onChange={this.handleChange}
          onBlur={this.handlePwBlur}
          style={{ marginBottom: '0' }}
        />

        <input
          name="password2"
          type="password"
          value={password2}
          placeholder={'Repeat password'}
          onChange={this.handleChange}
          onBlur={this.handlePwBlur}
        />

        <span
          id="signup-error-msg"
          // ref={this.errorMsgRef}
          className={`error-message${authFail || checkPwMsg ? '' : ' fixed-height'}`}
        >
          {checkPwMsg ? 'Make sure passwords are the same.' : authFail}
        </span>

        <button
          className="button-regular"
          type="button"
          onClick={this.handleSignUp}
          disabled={!email || !password || !!authFail || !pwSameness}
        >
          Sign Up
        </button>
      </form>
    );
  }
}

interface LinkStateProps {
  authFail: string;
}

interface LinkDispatchProps {
  signupThunk: (state: SignUpFormState) => void;
  authReset: () => void;
}

const mapState = (state: ReduxState, ownProps?: any) => ({
  authFail: state.auth.authFail,
});

const mapDispatch = (dispatch: ThunkDispatch<{}, {}, any>, ownProps?: any): LinkDispatchProps => ({
  signupThunk: async (userdata: SignUpFormState) => {
    await dispatch(authSignUp(userdata));
  },
  authReset: () => dispatch(authReset()),
});

export default connect(mapState, mapDispatch)(SignUpForm);
