export const indexFunc = (str: string) => {
  const index: string[] = [];
  const stringArr: string[] = str.split('');
  let curr = '';
  stringArr.forEach((letter) => {
    curr += letter.toLowerCase();
    index.push(curr);
  });
  return index;
};
