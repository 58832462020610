import React from 'react';
import { connect } from 'react-redux';
import { ReduxState } from '../../Store';
import { ThunkDispatch } from 'redux-thunk';
import { Router, Route, Switch } from 'react-router-dom';

import { createBrowserHistory } from 'history';

import Menu from '../TaskReminder/Menu/Menu';
import LoginPage from '../LoginPage/LoginPage';
import GitHub from './GitHub/GitHub';

const history = createBrowserHistory();

interface TaskReminderProps {}

interface TaskReminderState {}

type Props = TaskReminderProps & LinkDispatchProps & LinkStateProps;

class TaskReminder extends React.Component<Props, TaskReminderState> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Router history={history}>
        <div className="TaskReminder">
          <Menu />
          <div className="TaskReminder-routes">
            Work in Progress :) Thanks for viewing!
            <Switch>
              <Route path="/github" component={GitHub} />
            </Switch>
          </div>
        </div>
      </Router>
    );
  }
}

interface LinkDispatchProps {}

interface LinkStateProps {}

const mapState = (state: ReduxState) => ({});

const mapDispatch = (
  dispatch: ThunkDispatch<{}, {}, any>,
  ownProps?: any
): LinkDispatchProps => ({});

export default connect(mapState, mapDispatch)(TaskReminder);
