// @ts-nocheck
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import { createFirestoreInstance } from 'redux-firestore';
import store from '../Store';

const config = {
  apiKey: process.env.REACT_APP_apikey,
  authDomain: 'taskreminder-60603.firebaseapp.com',
  databaseURL: 'https://taskreminder-60603.firebaseio.com',
  projectId: 'taskreminder-60603',
  storageBucket: 'taskreminder-60603.appspot.com',
  messagingSenderId: process.env.REACT_APP_messagingSenderId,
  appId: process.env.REACT_APP_appId,
  measurementId: process.env.REACT_APP_measurementId,
};

firebase.initializeApp(config);
export const Firestore = firebase.firestore();

const rrfConfig = {
  userProfile: 'users', // Uses 'users' collection in firestore
  useFirestoreForProfile: true, // Firestore for Profile instead of Realtime DB
  // attachAuthIsReady: true, // Attaches authisready promise to store
};

export const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance,
};

const fbauth = firebase.auth();

// Instantiate google Oauth provider, request access to user profile info
const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
googleAuthProvider.setCustomParameters({ prompt: 'select_account' });
googleAuthProvider.addScope('profile');

// Google Cloud Functions
const cloudFunctions = firebase.functions();

export { fbauth, cloudFunctions, googleAuthProvider };

export default firebase;
