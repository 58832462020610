import React from 'react';
import { connect } from 'react-redux';
import { ReduxState } from '../../../Store';
import { ThunkDispatch } from 'redux-thunk';
import { authLogout } from '../../../Store/Actions/AuthActions';
import { Link, Switch, Route, Router } from 'react-router-dom';

interface MenuProps {}

type Props = MenuProps & LinkStateProps & LinkDispatchProps;

interface MenuState {}

class Menu extends React.Component<Props, MenuState> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {};

  render() {
    return (
      <div className="menu">
        <title>Tasks</title>

        <div className="menu-section">
          <span className="menu-section-title">GitHub</span>
          <Link className="menu-link" to="/github/repos">
            <span>Repos</span>
          </Link>
        </div>
        <div className="menu-section">
          <span className="menu-section-title">Tasks</span>
          <Link className="menu-link" to="/tasks">
            <span>View All</span>
          </Link>
        </div>
        <div className="menu-section">
          <span className="menu-section-title">Notes</span>
          <Link className="menu-link" to="/notes">
            <span>View All</span>
          </Link>
        </div>
        <div className="vert-filler" />
        <div>
          <a onClick={this.props.logout}>Log Out</a>
        </div>
      </div>
    );
  }
}

interface LinkStateProps {
  authFail: string;
}

interface LinkDispatchProps {
  logout: () => void;
}

const mapState = (state: ReduxState, ownProps?: any) => ({});

const mapDispatch = (dispatch: ThunkDispatch<{}, {}, any>, ownProps?: any): LinkDispatchProps => ({
  logout: async () => {
    await dispatch(authLogout());
  },
});

export default connect(mapState, mapDispatch)(Menu);
