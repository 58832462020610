import React from 'react';
import { connect } from 'react-redux';
import { ReduxState } from '../../../Store';
import { ThunkDispatch } from 'redux-thunk';
import { authLogout } from '../../../Store/Actions/AuthActions';
import axios, { AxiosResponse } from 'axios';
import { cloudFunctions } from '../../../Firebase/firebase';
import { Route, Switch, withRouter, RouteComponentProps, Redirect } from 'react-router-dom';
import GHLoginHOC from './GitHubLogin';
import Loading from '../../Loading/Loading';
import GitHubRepos from './GitHubRepos';

interface GitHubProps extends RouteComponentProps<any> {}

type Props = GitHubProps & LinkStateProps & LinkDispatchProps;

interface GitHubState {
  route: string;
  isGHLoggedIn: boolean;
}

class GitHub extends React.Component<Props, GitHubState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      route: '',
      isGHLoggedIn: false,
    };
  }

  componentDidUpdate = async (prevprops: GitHubProps) => {
    // let route = this.props.history.location.pathname;
    // let isGHLoggedIn = this.state.isGHLoggedIn;
    // debugger;
    // // Once firebase profile is loaded, check for github access token:
    // if (this.props.fbprofile.isLoaded) {
    //   try {
    //     const gh = this.props.fbprofile.github;
    //     const token = gh ? gh.token : '';

    //     // GH API: Check user token.
    //     await axios.get('https://api.github.com/user', {
    //       headers: { Authorization: 'token ' + token },
    //     });

    //     isGHLoggedIn = true;
    //     if (route !== this.state.route && !!this.state.route) {
    //       route = this.state.route;
    //     }
    //   } catch (error) {
    //     isGHLoggedIn = false;
    //   }
    // }

    // this.setState({
    //   route,
    //   isGHLoggedIn,
    // });
  };

  // getCodeInUrl = () => {
  //   const search = window.location.search;
  //   const params = new URLSearchParams(search);
  //   return params.get('code');
  // };

  // goLogin = () => {
  //   const githubOauth = 'https://github.com/login/oauth/authorize';
  //   const clientId = '94f7b94d40f063f7660a';
  //   let scopes = 'repo read:org read:user';
  //   scopes = scopes.replace(/ /g, '%20');
  //   window.location.href = githubOauth + '?client_id=' + clientId + '&scope=' + scopes;
  // };

  render() {
    const { isLoaded } = this.props.fbprofile;
    const { route, isGHLoggedIn } = this.state;
    return (
      <div className="GitHub">
        {!isGHLoggedIn && isLoaded && <Redirect to="/github/login" />}
        <Switch>
          <Route path={`github/login`} component={GHLoginHOC(GitHubRepos)} />
          <Route path={`github/repos`}>{/* <GitHubRepos /> */}</Route>
        </Switch>
      </div>
    );
  }
}

interface LinkStateProps {
  fbprofile: any;
}

interface LinkDispatchProps {}

const mapState = (state: ReduxState, ownProps?: any) => ({
  fbprofile: state.firebase.profile,
});

const mapDispatch = (
  dispatch: ThunkDispatch<{}, {}, any>,
  ownProps?: any
): LinkDispatchProps => ({});

export default withRouter(connect(mapState, mapDispatch)(GitHub));
