import React from 'react';
import { connect } from 'react-redux';
import { ReduxState } from '../../Store';
import { ThunkDispatch } from 'redux-thunk';
import LoginForm from './LoginForm';
import SignUpForm from './SignUpForm';
import { authGoogleLogin } from '../../Store/Actions/AuthActions';

interface LoginPageProps {}

type Props = LoginPageProps & LinkStateProps & LinkDispatchProps;

interface LoginPageState {
  isLogin: boolean;
}

class LoginPage extends React.Component<Props, LoginPageState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isLogin: true,
    };
  }

  componentDidMount = () => {
    this.setContainerHeight();
  };

  setContainerHeight = (formType?: string) => {
    let height: number = this.state.isLogin ? 324 : 371;
    if (formType) {
      const errorSpan: HTMLElement | null = document.getElementById(formType + '-error-msg');
      const errorHeight: number = errorSpan ? errorSpan.clientHeight : 0;
      height += errorHeight;
    }

    const setHeight: string = height + 'px';
    (document.getElementById('login-page-container') as HTMLElement).style.height = setHeight;
  };

  toggleForm = async () => {
    await this.setState({
      isLogin: !this.state.isLogin,
    });
    this.setContainerHeight();
  };

  render() {
    const { isLogin } = this.state;
    const { authGoogleLogin } = this.props;
    return (
      <div className="loginPage">
        <div className="loginPage-title">
          {`"So much to do, so little time."`}
        </div>
        <div id="login-page-container" className="loginPage-container">
          <header>{isLogin ? 'Log In' : 'Sign Up'}</header>
          <LoginForm isLogin={isLogin} setContainerHeight={this.setContainerHeight} />
          <SignUpForm isLogin={isLogin} setContainerHeight={this.setContainerHeight} />

          <a className="google-login" href="/#" onClick={authGoogleLogin}>
            Login With Google
          </a>
          <a className="goto-signup" href="/#" onClick={this.toggleForm}>
            {isLogin ? 'Sign Up' : 'Login'}
          </a>
        </div>
      </div>
    );
  }
}

interface LinkStateProps {
  authFail: string;
}

interface LinkDispatchProps {
  authGoogleLogin: () => void;
}

const mapState = (state: ReduxState, ownProps?: any) => ({
  authFail: state.auth.authFail,
});

const mapDispatch = (dispatch: ThunkDispatch<{}, {}, any>, ownProps?: any): LinkDispatchProps => ({
  authGoogleLogin: async () => {
    await dispatch(authGoogleLogin());
  },
});

export default connect(mapState, mapDispatch)(LoginPage);
