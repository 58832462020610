export interface ActionType {
  type: string;
  payload?: any;
}

export const AUTH_START = 'AUTH_START';

export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_RESET = 'AUTH_RESET';


export const AUTH_CREDS = 'AUTH_CREDS';

export const DONE_LOADING = 'DONE_LOADING';
