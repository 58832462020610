import React from 'react';
import { connect } from 'react-redux';
import { ReduxState } from '../../Store';
import { authReset, authLogin } from '../../Store/Actions/AuthActions';
import { ThunkDispatch } from 'redux-thunk';
import { isEmail, validEmail } from '../Utils/Utils';

interface LoginFormProps {
  isLogin: boolean;
  setContainerHeight: (height?: string) => {};
}

type Props = LoginFormProps & LinkStateProps & LinkDispatchProps;

interface LoginFormState {
  email: string;
  password: string;
}

class LoginForm extends React.Component<Props, LoginFormState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      email: '',
      password: '',
    };
  }

  handleLogin = () => {
    const isemail = isEmail(this.state.email);
    if (isemail) {
      const isEmailValid = validEmail(this.state.email);
      isEmailValid && this.props.loginThunk(this.state);
    }
    return;
  };

  handleChange = async (e: React.SyntheticEvent<HTMLInputElement>) => {
    if (this.props.authFail) {
      this.props.authReset();
      this.props.setContainerHeight();
    }

    const target = e.target as HTMLInputElement;
    await this.setState({ [target.name]: target.value } as Pick<
      LoginFormState,
      keyof LoginFormState
    >);
  };

  componentDidUpdate = (prevProps: Props) => {
    if (prevProps.authFail !== this.props.authFail) {
      this.props.setContainerHeight('login');
    }
  };

  render() {
    const { email, password } = this.state;
    const { authFail, isLogin } = this.props;

    return (
      <form className={`login-form${isLogin ? '' : ' hidden'}`}>
        <label htmlFor="email">Email</label>
        <input
          name="email"
          type="text"
          value={email}
          placeholder={'Email or Username'}
          onChange={this.handleChange}
        />

        <label htmlFor="password">Password</label>
        <input
          name="password"
          type="password"
          value={password}
          placeholder={'Password'}
          onChange={this.handleChange}
        />

        <span id="login-error-msg" className={`error-message${authFail ? '' : ' fixed-height'}`}>
          {authFail}
        </span>

        <button
          className="button-regular"
          type="button"
          onClick={this.handleLogin}
          disabled={!email || !password || !!authFail}
        >
          Login
        </button>
      </form>
    );
  }
}

interface LinkStateProps {
  authFail: string;
}

interface LinkDispatchProps {
  loginThunk: (state: LoginFormState) => void;
  authReset: () => void;
}

const mapState = (state: ReduxState, ownProps?: any) => ({
  authFail: state.auth.authFail,
});

const mapDispatch = (dispatch: ThunkDispatch<{}, {}, any>, ownProps?: any): LinkDispatchProps => ({
  loginThunk: async (userdata: LoginFormState) => {
    await dispatch(authLogin(userdata));
  },
  authReset: () => dispatch(authReset()),
});

export default connect(mapState, mapDispatch)(LoginForm);
