import * as Actions from '../Actions/ActionTypes';

export interface AuthState {
  authFail: string;
  isLoggedIn: boolean;
}

const initialState: AuthState = {
  authFail: '',
  isLoggedIn: false,
};

const AuthReducer = (state: AuthState = initialState, { type, payload }: Actions.ActionType) => {
  switch (type) {
    case Actions.AUTH_START:
      return {
        ...state,
        authFail: '',
      };
    case Actions.AUTH_FAIL:
      return {
        ...state,
        authFail: payload,
      };
    case Actions.AUTH_LOGIN:
      return {
        ...state,
        authFail: '',
        isLoggedIn: true,
      };
    case Actions.AUTH_LOGOUT:
      return {
        ...state,
        authFail: '',
        isLoggedIn: false,
      };
    case Actions.AUTH_RESET:
      return initialState;

    default:
      return state;
  }
};

export default AuthReducer;
