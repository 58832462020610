// PACKAGES
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { getFirebase, firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';

// REDUCERS
import AuthReducer from './Reducers/AuthReducer';
import AppReducer from './Reducers/AppReducer';

// TYPES
import { Reducer } from 'redux';
import { AuthState } from './Reducers/AuthReducer';
import { AppState } from './Reducers/AppReducer';

export interface ReduxState {
  auth: AuthState;
  app: AppState;
  firebase: any;
  firestore: any;
}

const reducer: Reducer<ReduxState> = combineReducers({
  auth: AuthReducer,
  app: AppReducer,
  firebase: firebaseReducer,
  firestore: firestoreReducer,
});

// Chrome devtools redux extension:
const composeEnhancers =
  process.env.NODE_ENV === 'development'
    ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    : compose;

const store = createStore(
  reducer,
  composeEnhancers(applyMiddleware(thunk.withExtraArgument({ getFirebase })))
);

export default store;
