import * as Actions from '../Actions/ActionTypes';

export interface AppState {
  isLoading: boolean;
}

const initialState: AppState = {
  isLoading: true,
};

const AppReducer = (state: AppState = initialState, { type, payload }: Actions.ActionType) => {
  switch (type) {
    case Actions.DONE_LOADING: {
      return { ...state, ...payload };
    }
    case Actions.AUTH_LOGIN: {
      return { ...state, isLoading: false };
    }

    default:
      return state;
  }
};

export default AppReducer;
