import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ReduxState } from '../../Store';
import Loading from '../Loading/Loading';
import LoginPage from '../LoginPage/LoginPage';
import TaskReminder from '../TaskReminder/index';

interface AppProps {}

interface AppState {}

interface LinkStateProps {
  isLoggedIn: boolean;
  isProfileLoaded: boolean;
}

interface LinkDispatchProps {}

type Props = AppProps & LinkDispatchProps & LinkStateProps;

class App extends React.Component<Props, AppState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isAppLoading: true,
    };
  }

  render() {
    const { isLoggedIn, isProfileLoaded } = this.props;
    return (
      <div id="App">
        <Loading />
        {isLoggedIn && isProfileLoaded ? <TaskReminder /> : <LoginPage />}
      </div>
    );
  }
}

// const maomaomoo = true;
// const moomoomao = maomaomoo;

const mapState = (state: ReduxState) => ({
  isLoggedIn: !state.firebase.auth.isEmpty,
  isProfileLoaded: state.firebase.profile.isLoaded,
});

const mapDispatch = (dispatch: Dispatch, ownProps?: any): LinkDispatchProps => ({});

export default connect(mapState, mapDispatch)(App);
