import React from 'react';
import { connect } from 'react-redux';
import { ReduxState } from '../../../Store';
import { ThunkDispatch } from 'redux-thunk';
import { authLogout } from '../../../Store/Actions/AuthActions';
import axios, { AxiosResponse } from 'axios';
import { cloudFunctions } from '../../../Firebase/firebase';
import { withRouter, RouteComponentProps } from 'react-router-dom';

interface GitHubLoginProps extends RouteComponentProps<any> {
  route: string;
}

type Props = GitHubLoginProps & LinkStateProps & LinkDispatchProps;

interface GitHubLoginState {
  isGHLoggedIn: boolean;
}

const GHLoginHOC = (Child: React.ComponentType<any | string>) => {
  class GitHubLogin extends React.Component<Props, GitHubLoginState> {
    constructor(props: Props) {
      super(props);
      this.state = {
        isGHLoggedIn: false,
      };
    }

    componentDidMount = async () => {
      // Get user's github token and test--make api call;

      // If failed, show logintogithub;
      // else show github data.

      const code = this.getCodeInUrl();
      // If url has code, this is a redirect from GH Oauth.
      if (code) {
        // Show loading, bc it takes a while to get token...

        // Get access token via cloud function.
        try {
          const getGHToken = cloudFunctions.httpsCallable('getGHToken');
          const res = await getGHToken({ code });
          const resdata = res.data;

          // handle error
          if (resdata.error) {
            if (typeof resdata.errorRes === 'string') {
              if (resdata.errorRes.includes('expired') > -1) {
                // handle error: token is expired.
                // debugger;
              }
            }
          }

          debugger;
          //redirect to route;
          // this.routes.history.push('');
        } catch (error) {
          // debugger;
        }
      }
    };

    getCodeInUrl = () => {
      const search = window.location.search;
      const params = new URLSearchParams(search);
      return params.get('code');
    };

    goLogin = () => {
      const githubOauth = 'https://github.com/login/oauth/authorize';
      const clientId = '94f7b94d40f063f7660a';
      let scopes = 'repo read:org read:user';
      scopes = scopes.replace(/ /g, '%20');
      window.location.href = githubOauth + '?client_id=' + clientId + '&scope=' + scopes;
    };

    render() {
      const { isGHLoggedIn } = this.state;
      return (
        <div>
          {isGHLoggedIn ? (
            <Child />
          ) : (
            <div className="GitHubLogin">
              <button className="button-regular" onClick={this.goLogin}>
                Login to GitHubLogin
              </button>
            </div>
          )}
        </div>
      );
    }
  }

  return GitHubLogin;
};

export default GHLoginHOC;

interface LinkStateProps {}

interface LinkDispatchProps {}

// const mapState = (state: ReduxState, ownProps?: any) => ({});

// const mapDispatch = (
//   dispatch: ThunkDispatch<{}, {}, any>,
//   ownProps?: any
// ): LinkDispatchProps => ({});

// export default withRouter(connect(mapState, mapDispatch)(GHLoginHOC));
