import React from 'react';
import { connect } from 'react-redux';
import { ReduxState } from '../../Store';

type Props = LinkStateProps;

interface LinkStateProps {
  isLoaded: boolean;
}

const Loading = (props: Props) => {
  const { isLoaded } = props;
  return (
    <div id="loading-page" className={`loading ${!isLoaded ? `show` : `hide`}`}>
      Loading...
    </div>
  );
};

const mapState = (state: ReduxState) => ({
  isLoaded: state.firebase.profile.isLoaded,
});

export default connect(mapState)(Loading);
